import { map } from "@firebase/util";
import React, { useEffect, useState } from "react";
import { questions } from '../config'
import './main.css'


const MainFormArea = ({ writeToDb }) => {
    const [q1, setQ1] = useState('');
    const [q2, setQ2] = useState('');
    const [q3, setQ3] = useState('');
    const [q4, setQ4] = useState('');

    const postData = () => {
        let empty = [q1, q2, q3, q4].some(q => q.length == 0)
        if (empty) {
            window.alert('Field empty!');
            return;
        }
        writeToDb(q1, q2, q3, q4);
        setQ1('');
        setQ2('');
        setQ3('');
        setQ4('');
    }


    return (

        <div className="form-area" >

            <div className="question-container mb-3">
                <div className="question-title form-label">{questions.q1}</div>
                <div className="question-body">
                    <textarea type={'text'} className={'input-text-body form-control'} value={q1} onChange={e => setQ1(e.target.value)} />
                </div>
            </div>

            <div className="question-container mb-3">
                <div className="question-title form-label">{questions.q2}</div>
                <div className="question-body">
                    <textarea type={'text'} className={'input-text-body form-control'} value={q2} onChange={e => setQ2(e.target.value)} />
                </div>
            </div>

            <div className="question-container mb-3">
                <div className="question-title form-label">{questions.q3}</div>
                <div className="question-body">
                    <textarea type={'text'} className={'input-text-body form-control'} value={q3} onChange={e => setQ3(e.target.value)} />
                </div>
            </div>

            <div className="question-container mb-3">
                <div className="question-title form-label">{questions.q4}</div>
                <div className="question-body">
                    <textarea type={'text'} className={'input-text-body form-control'} value={q4} onChange={e => setQ4(e.target.value)} />
                </div>
            </div>

            <button type="submit" className="btn btn-primary" onClick={() => postData()}>Submit</button>

        </div>
    )
}


const History = ({ historyData, setHistoryPath }) => {

    var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const [_mnt, _setMnt] = useState((new Date().getMonth() + 1))
    const [_date, _setDate] = useState((new Date().getDate()))


    const accordionId = "ac1";

    const changeMonth = selected => {
        console.log('selected: ', selected);
        console.log('path: ', new Date().getFullYear() + "/" + selected);
        setHistoryPath(new Date().getFullYear() + "/" + selected);
    }

    useEffect(() => {
        //console.log(Object.keys(historyData))
        //console.log('mnt', mnt)
    }, [historyData])

    return (
        <div>
            <div className="history-header">
                <div className="history-date-picker">
                    <div>
                        <select className="form-select" onChange={e => changeMonth(e.target.value)}>
                            {
                                months.map((m, ndx) => {
                                    let _val = (ndx + 1);
                                    //console.log(_val, mnt)
                                    return (
                                        <option value={_val.toString()} selected={_mnt == _val} key={'val_' + ndx}>
                                            {m}
                                        </option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div>
                        <ul className="pagination">
                            {
                                Object.keys(historyData).map((val, ndx) => {
                                    return (
                                        <li className={_date == val ? "page-item active" : "page-item"} key={'val_' + ndx}>
                                            <button className="page-link" onClick={() => _setDate(val)} >{val}</button>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
           


            </div>

            <div className="accordion" id={accordionId}>
                {
                    historyData[_date] ? Object.keys(historyData[_date]).map((val, ndx) => {
                        let headerId = "key_" + ndx;
                        let targetId = "target_" + ndx;

                        var mapData = historyData[_date]

                        return (
                            <div className="accordion-item" key={'val_' + ndx}>
                                <div className="accordion-header" id={headerId}>
                                    <button className="accordion-button" data-bs-target={"#" + targetId} type={'button'} data-bs-toggle="collapse">
                                        {val + "@spacewalkautomation.com"}
                                    </button>
                                </div>

                                <div aria-labelledby={headerId} id={targetId} className={'accordion-collapse collapse'} data-bs-parent={"#" + accordionId}>
                                    <div className="accordion-body">

                                        <ol>
                                            <div className="history-data-item">
                                                <li>
                                                    <div className="history-user-question">
                                                        {questions['q1']}
                                                    </div>
                                                </li>
                                                <div className="history-user-answer">
                                                    {mapData[val]['q1']}
                                                </div>
                                            </div>
                                            <div className="history-data-item">
                                                <li>
                                                    <div className="history-user-question">
                                                        {questions['q2']}
                                                    </div>
                                                </li>
                                                <div className="history-user-answer">
                                                    {mapData[val]['q2']}
                                                </div>
                                            </div>
                                            <div className="history-data-item">
                                                <li>
                                                    <div className="history-user-question">
                                                        {questions['q3']}
                                                    </div>
                                                </li>
                                                <div className="history-user-answer">
                                                    {mapData[val]['q3']}
                                                </div>
                                            </div>
                                            <div className="history-data-item">
                                                <li>
                                                    <div className="history-user-question">
                                                        {questions['q4']}
                                                    </div>
                                                </li>
                                                <div className="history-user-answer">
                                                    {mapData[val]['q4']}
                                                </div>
                                            </div>

                                        </ol>
                                    </div>
                                </div>

                            </div>
                        )
                    }) : (<div></div>)
                }
            </div>
        </div>
    )
}

/*

* 



*/

export default function Main({ user, writeToDb, historyData, setHistoryPath, logoutAction }) {

    const [showForm, setShowForm] = useState(true)



    useEffect(() => {


    }, [showForm])



    return (
        <div className="main-container">
            <div className="header-area">
                <div className="date-area">
                    {new Date().toDateString()}
                </div>
                <div className="title-area">
                    <h3>Daily update</h3>
                    <h6>Spacewalk Automation</h6>
                </div>
                <div className="id-area">
                    <div>
                        {user.email}
                    </div>
                    <div>
                        <button className="btn btn-secondary" onClick={() => logoutAction()}>signOut</button>
                    </div>
                </div>


            </div>

            <div className="nav nav-tabs">
                <div className="nav-item"><div className={showForm ? "nav-link active" : "nav-link"} onClick={() => setShowForm(true)}>Log</div></div>
                <div className="nav-item"><div className={showForm ? "nav-link" : "nav-link active"} onClick={() => setShowForm(false)} >History</div></div>
            </div>



            <div className="body-area">
                {showForm ? <MainFormArea writeToDb={writeToDb} /> : <History historyData={historyData} setHistoryPath={setHistoryPath} />}
            </div>
        </div>
    )
}

/**
 * .title-area{
    width: 100%;
    text-align: center;
}

.id-area{
    text-align:right;
}

.date-area{
    text-align: left;
}
 */