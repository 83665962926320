import React, { useState } from "react";
import logo from './logo.png'


export default function Login({ loginAction, loginLoading }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  return (
    <div className="login-container">

      <div className="header-area">
        <div className="title-area">
          <h2>Daily update</h2>
          <h6>Spacewalk Automation</h6>
        </div>
        <img src={logo} width={'100px'} style={{}} />

      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px', border: '0px solid red' }}>

      </div>

      <div className="login-form">

        <div className="user-name-form mb-3">
          <label typeof="email" className="form-label">Email</label>
          <input value={email} type="email" className="form-control" onChange={e => setEmail(e.target.value)} />
        </div>
        <div className="password-form  mb-3">
          <label typeof="email" className="form-label">Password</label>
          <input value={password} type="password" className="form-control" onChange={e => setPassword(e.target.value)} />
        </div>

        <div className="login-btn-area">
          <button type="submit" class="btn btn-primary" onClick={() => loginAction(email, password)}>Login</button>
          <div className="spinner-border text-primary" role="status" style={loginLoading? {display:'block', marginLeft:10}:{display:'none'}}>
           
          </div>
        </div>

      </div>
    </div>
  )
}


/*

<form>
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Email address</label>
    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
    <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
  </div>
  <div class="mb-3">
    <label for="exampleInputPassword1" class="form-label">Password</label>
    <input type="password" class="form-control" id="exampleInputPassword1">
  </div>
  <div class="mb-3 form-check">
    <input type="checkbox" class="form-check-input" id="exampleCheck1">
    <label class="form-check-label" for="exampleCheck1">Check me out</label>
  </div>
  <button type="submit" class="btn btn-primary">Submit</button>
</form>


*/