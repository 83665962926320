
import * as firebase from 'firebase/app';
import * as firebaseAuth from "firebase/auth";
import * as firebaseDb from "firebase/database";
import { firebaseConfig } from "./config";
import { useEffect, useState } from 'react';
import Main from './Screens/main';
import Login from './Screens/login';
import { signOut } from 'firebase/auth';



function App() {


  const [authenticated, setAuthenticated] = useState(false);
  const firebaseApp = firebase.initializeApp(firebaseConfig);
  const auth = firebaseAuth.getAuth();
  const database = firebaseDb.getDatabase(firebaseApp);

  const [user, setUser] = useState({})
  const [historyData, setHistoryData] = useState({})
  const [historyPath, setHistoryPath] = useState(new Date().getFullYear() + "/" + (new Date().getMonth() + 1))

  const [logErrShow, setLogErrShow] = useState(false);
  const [logErrMsg, setLogErrMsg] = useState('');
  const [loginLoading, setLoginLoading] = useState(false);

  const writeToDb = (q1, q2, q3, q4) => {

    console.log("writing to db");
    var d = new Date();
    let str = user.email;
    console.log(str.substring(0, str.length - 24));
    let day_path = d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + (d.getDate()) + "/" + str.substring(0, str.length - 24)

    firebaseDb.set(firebaseDb.ref(database, day_path), {
      q1: q1,
      q2: q2,
      q3: q3,
      q4: q4
    }).then(val => {
      //window.alert("Successfully Posted!");
      console.log(val)
      setLogErrShow(true);
      setLogErrMsg('Update Posted successfully!');
      setTimeout(() => {
        setLogErrShow(false);
      }, 3000);
      window.scrollTo(0, 0);
    })
      .catch(er => console.log(er))

    getDbData(historyPath);
  }

  const getDbData = _path => {
    setHistoryData({})
    console.log('get data called')

    console.log(_path)
    const dbRef = firebaseDb.ref(database);
    firebaseDb.get(firebaseDb.child(dbRef, _path))
      .then(snap => {
        if (snap.exists()) {
          console.log(snap.val())
          setHistoryData(snap.val())
        }

      })
      .catch(er => console.error(er))
      .finally(() => {
        console.log('data function call over')
      })
  }

  const loginAction = (email, password) => {

    setLoginLoading(true);

    firebaseAuth.signInWithEmailAndPassword(auth, email, password)
      .then(result => {
        console.log("got result", result);
        setAuthenticated(true);
        setUser(result.user);
        setLoginLoading(false);
        setLogErrShow(false);

      }).catch(er => {
        console.log('failed')
        setLogErrShow(true);
        setLogErrMsg('login failed');
        setLoginLoading(false);
        setTimeout(() => {
          setLogErrShow(false);
        }, 3000);

      })

  }

  const logoutAction = () => {
    //signOut();
    setUser({});
    signOut(auth)
      .then(res => {
        console.log(res);
        setLogErrShow(false);
        setAuthenticated(false);
      })
      .catch(er => console.log(er))

  }



  useEffect(() => {

    //var d = new Date();
    //let day_path = d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate() + "/" + user.email
    getDbData(historyPath);


  }, [historyPath])

  return (
    <div className="App">
      <div className="alert alert-primary" role="alert" style={logErrShow ? { display: 'block' } : { display: 'none' }}>
        {logErrMsg}
      </div>
      {authenticated ? <Main user={user} writeToDb={writeToDb} historyData={historyData} setHistoryPath={setHistoryPath} logoutAction={logoutAction} /> : <Login loginAction={loginAction} loginLoading={loginLoading} />}

    </div>
  );
}




// <Main user={user} />

//{authenticated ? <Main user={user} /> : <Login loginAction={loginAction} />}

export default App;
