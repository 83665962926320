// Import the functions you need from the SDKs you need

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const questions = {
  q1: "Please explain as best as possible all the milestones achieved today.",
  q2: "Please list out any delays, issues, risks, uncertainties, and their current status.",
  q3: "Please list out plans for the following working day.",
  q4: "Any other information you feel is necessary to know about your progress."
}





const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
};

export { firebaseConfig, questions };



// Initialize Firebase


/*

firebase login
firebase init
firebase deploy

*/
